import { MouseEvent } from 'react';
import { BaseButton } from './base';
import { TButtonSize, TButtonColor } from './button.type';
import NextLink from 'next/link';

export type Props = {
  text: string;
  href: string;
  color?: TButtonColor;
  size?: TButtonSize;
  icon?: string;
  clickHandler?: (e: MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  disabledTooltip?: string;
  isLoading?: boolean;
  className?: string;
  textClassName?: string;
};

function Link({
  text,
  href,
  color,
  size,
  icon,
  clickHandler,
  isDisabled = false,
  disabledTooltip,
  isLoading = false,
  className,
  textClassName,
}: Props) {
  return (
    <NextLink href={href}>
      <a
        onClick={!isDisabled && clickHandler ? clickHandler : () => {}}
        title={isDisabled ? disabledTooltip : undefined}
      >
        <BaseButton
          text={text}
          color={color}
          size={size}
          icon={icon}
          isDisabled={isDisabled}
          isLoading={isLoading}
          className={className}
          textClassName={textClassName}
        />
      </a>
    </NextLink>
  );
}

export { Link };
